exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-donation-failure-tsx": () => import("./../../../src/pages/donation-failure.tsx" /* webpackChunkName: "component---src-pages-donation-failure-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../../../src/pages/landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */),
  "component---src-pages-message-tsx": () => import("./../../../src/pages/message.tsx" /* webpackChunkName: "component---src-pages-message-tsx" */),
  "component---src-pages-redeem-thankyou-tsx": () => import("./../../../src/pages/redeem-thankyou.tsx" /* webpackChunkName: "component---src-pages-redeem-thankyou-tsx" */),
  "component---src-pages-redeem-tsx": () => import("./../../../src/pages/redeem.tsx" /* webpackChunkName: "component---src-pages-redeem-tsx" */),
  "component---src-pages-regions-tsx": () => import("./../../../src/pages/regions.tsx" /* webpackChunkName: "component---src-pages-regions-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */)
}

