import React from "react";
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import "bootstrap/dist/css/bootstrap.min.css";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";
import '@fortawesome/fontawesome-free/js/all.js';

import enBlog from './src/locales/en/blog.json';
import deBlog from './src/locales/de/blog.json';
import countries from 'i18n-iso-countries';
import enLayout from './src/locales/en/layout.json';
import deLayout from './src/locales/de/layout.json';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'de',                              // language to use
    resources: {
        en: {
            blog: enBlog,               // 'common' is our custom namespace
            layout: enLayout
        },
        de: {
            blog: deBlog,
            layout: deLayout
        },
    },
});

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

export const wrapRootElement = ({ element }) => (
    <I18nextProvider i18n={i18next}>
        {element}
    </I18nextProvider>
);